 .card{
    width: 50%;
    height: 80vh;
   
    border: 1px solid black;
   
    
    
} 
html{
    width: 100%;
    height: 100vh;
}
article{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 80vh;
    margin-left: 11%;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
   
    

    

    
   
    opacity: 0;
   
    
    
} 
.text{
    padding: 10px;
    margin-left: 10%;
}

.content{
    margin-top: 0;
}


 article.activeSlide{
    opacity: 1;
    transform: translateX(0);
}
article.lastSlide{
    transform: translateX(-100%);
}
article.nextSlide{
    transform: translateX(100%);

} 

.section{
    width: 100%;
    height: 100vh;
    background: -webkit-linear-gradient(top, #10c7a2, #4a1d8a);
    background-repeat: no-repeat;
    background-size: cover;
   /* margin-left: 25%; */
   position: absolute;
   /* top: 40px; */
   padding: 50px;
   
}
.section-center{
    margin: 0 auto;
    margin-top: 4rem;
    width: 80vw;
    height: 450px;
    max-width: 800px;
    margin-top: 10px;
    opacity: 0.7;
    
    position: relative;
    display: flex;
    overflow: hidden;
}
.head{
    margin-left: 35%;
    margin-top: 7%;
}
@media(max-width: 1000px){
    article{
        width: 100%;
        margin: auto;
    }
    .section{
        padding: 0;
    }
    .section-center{
        width: 100%;
        margin-top: 10%;
    }
    .bu{
        margin-left: 22%;
    }
    
}
/* .prev,.next{
    position: absolute;
    top: 200px;
    transform: translateY(-50%);
    background: hsl(210, 22%, 49%);
    color: #fff;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
   
    

} */
.prev:hover,.next:hover{
    background: hsl(21, 62%, 45%);
}
.prev{
    left: 0;
}
.next{
    right: 0;
}
.content{
    margin-top: 20%;
}

    
    

#button1{
    width: 10%;
    height: 10%;
    /* margin: 10%; */
    margin-left: 20%;
    background-color: #19095a;
    border: none;
    color: white;
    margin-top: 5%;
    
    
}
#button:hover{
    background-color: transparent;
    color: #19095a;
    border: 1px solid #19095a;
}
form{
    margin-top: 5%;
}
@media(max-width: 1000px){
    article{
        margin-left: 10%;
        width: 80%;
        height:auto;
        padding-bottom: 50px;
    }
    form{
        margin-left: 6%;
    }
    #button1{
        width: 20%;
    }
    .header {
        margin-top: 22%;
        margin-left: 22%;
    }
}
.head{
    margin-left: 33%;
}

th, td {
    width:150px;
    text-align:left;
    border:1px solid black;
    padding:5px
 
}
th{
    font-size: 21px;
    font-weight: 700;
    color: #000;
}
td{
    font-size: 17px;
    font-weight: 500;
    color: #000;
}
#error{    
    font-size: 17px;
    font-weight: 500;
    color: red;
}
#mostleast{
    font-size: 19px;
    font-weight: 600;
    color: #000 !important;
}
#checkbox {
    margin-left: 12px;
    transform: scale(2.0); /* Adjust the scale value as per your requirement */
  }
