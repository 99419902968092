.item{
    padding: 15px;
    /* margin-left: 8%; */


}
.text1{
    margin-left: 30px;
}
.body{
    width: 100%;
    height: 100vh;
    background: -webkit-linear-gradient(top, #10c7a2, #4a1d8a);
    background-repeat: no-repeat;
    background-size: cover;
   /* margin-left: 25%; */
   position: absolute;
   top: 40px;
   padding: 50px;
   
  
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -19%;
  }

.form{
    width: 60%;
    padding: 15px;
    margin-left: 19%;
   background-color: white;
    opacity: 0.5;

    /* background-image: url(./images/Untitled-5.png); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 25px;
    z-index: -1;

    /* background-position: center; */

}
.content{
   margin-top: 3%;
    margin-left: 10%;
    padding-bottom: 20px;
}
.input1{
    width: 70%;
    margin-left: 30px;
    outline: none;
    border: none;
    border-bottom: 2px solid #19095a ;
}

.button1{
    background-color:#19095a;
    text-decoration: none;
    padding: 6px 134px;
    border-radius: 16px;

    /* margin-top: 50px; */
    color: white;

    /* border-radius: 10px; */
}
.button1:hover{
    background-color: transparent;
    color: blue;
}
.bu{
    /* margin-top: 5%; */
     margin-left: 20%; 
}
.hea{
    margin-top: 6%;
    text-align: center;
    color: #000;
    font-size: 28px;
}

@media(max-width: 1000px){
    .form{
        margin-left: 3%;
        width: 100%;
        height:auto;
        padding-bottom: 50px;
    }
    .content{
        margin-top: 1%;
        margin-left: 0;

    }
    .button1{
        
        padding: 6px 70px;
       
    }
    .hea{
       
        margin-left: 20%;
    }


    
}

.input1::placeholder {
    font-size: 14px; /* Set the desired font size */
    color: #999; /* Set the desired color */
  }
